import http from '@/config/api';
import router from '@/router';
import { getSubdomainAndDirectory } from '@/helpers/common';

export default {
  createChargeCurrency(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(`/event/${subdomain}/child/${directory}/manual/charges`, payload)
  },
  createChargeTicketCurrency(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(`/event/${subdomain}/child/${directory}/ticket/manual/charges`, payload)
  },
  createPaymentCurrency(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(`/event/${subdomain}/child/${directory}/manual/payments`, payload)
  },
  createPaymentTicketCurrency(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(`/event/${subdomain}/child/${directory}/ticket/manual/payments`, payload)
  },
  importCurrencyCsv(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(`event/${subdomain}/child/${directory}/manual/import`, payload, { isMultipartContent: true });
  },
  importTicketCurrencyCsv(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(`event/${subdomain}/child/${directory}/ticket/manual/import`, payload, { isMultipartContent: true });
  },
  getImportStatus(_, id) {
    return http.get(`import/manual/${id}/status`).then((response) => {
      return response?.data?.result;
    });
  },
  checkCanUseCurrency(_, id) {
    const isCurrency = ['EventParentPointManagement', 'EventChildPointManagement'].includes(router.history.current.name) ? { isCurrency: 0 } : {};
    return http.get(`event/${id}/can-use-currency`, {
      params: {
        ...isCurrency
      },
      noResetErrorFields: true,
    }).then((response) => {
      return response?.data?.result;
    });
  },
  async getCategoriesList({ commit }, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    await http.get(`event/${subdomain}/child/${directory}/ticket/category`, payload).then((response) => {
      if (response) {
        const { list, count } = response.data;
        commit('SET_CATEGORIES_LIST', list);
        commit('SET_CATEGORY_COUNT', count);
      }
    });
  },
  async createCategory(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(`/event/${subdomain}/child/${directory}/ticket/category`, payload, {
      name: payload.name
    }).then((response) => response?.data);
  },
  async updateCategory(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(`/event/${subdomain}/child/${directory}/ticket/category/${payload.categoryId}`, { name: payload.categoryName })
      .then((response) => response?.data);
  },
  async deleteCategory(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.delete(`/event/${subdomain}/child/${directory}/ticket/category/${payload.categoryId}`)
      .then((response) => response?.data);
  },
  sortCategory(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(`event/${subdomain}/child/${directory}/ticket/sort/category`, payload.data);
  },
  getQrTicketList({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return http.get(`/event/${subdomain}/child/${directory}/ticket/qrcode`, { params }).then(response => {
      if (response) {
        const { list, count } = response.data;
        commit('SET_QR_TICKET_LIST', list);
        commit('SET_QR_TICKET_COUNT', count);
        return response?.data;
      }
    });
  },
  getAllQrTicketList({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return http.get(`/event/${subdomain}/child/${directory}/qrticket/release_otps`, { params }).then(response => {
      if (response) {
        commit('SET_ALL_QR_TICKET_LIST', (response.data?.data || []));
        commit('SET_ALL_QR_TICKET_COUNT', (response.data?.data?.length || 0));
        return response?.data?.data;
      }
    });
  },
  getTicketQRDetail({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return http.get(`/event/${subdomain}/child/${directory}/ticket/qrcode/${params.id}`).then(response => {
      if (response) {
        commit('SET_TICKET_QR_DETAIL', response.data);
        return response.data;
      }
    });
  },
  updateTicketQrStatus(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(
      `event/${subdomain}/child/${directory}/ticket/status/${payload.id}/qrcode`,
      payload.data
    );
  },
  removeTicketQRs(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.delete(`/event/${subdomain}/child/${directory}/ticket/qrcodes`, {
      data: {
        ...payload
      }
    });
  },
  getQrCodeTickets({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return http.get(`/event/${subdomain}/child/${directory}/qrcode/tickets`).then(response => {
      if (response) {
        commit('SET_QR_CODE_TICKETS', response?.data?.list);
        return response?.data?.list;
      }
    });
  },
  async getIssuerQrCodeList({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return await http.get(`/event/${subdomain}/child/${directory}/ticket/issuer/qrcode`, { params }).then(response => {
      if (response) {
        commit('SET_ISSUER_QR_CODE_LIST', response.data?.data);
      }
    });
  },
  createQrTicket(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(`/event/${subdomain}/child/${directory}/ticket/qrcode`, payload, { isMessageConverted: true }).then(response => response?.data?.id);
  },
  updateQrTicket(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(`/event/${subdomain}/child/${directory}/ticket/qrcode/${payload.id}`, payload, { isMessageConverted: true }).then(response => response?.data?.id);
  },
  getBalanceManual(_, payload) {
    const subdomain = payload?.subdomain || router.currentRoute.params.subdomain;
    const directory = payload?.directory || router.currentRoute.params.directory;
    return http.get(`event/${subdomain}/child/${directory}/customer/${payload.customerId}/balanceManual`, { payload }).then((response) => {
      return response?.data;
    });
  },
  getChargeTickets({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return http.get(`/event/${subdomain}/child/${directory}/tickets/charge`, { params }).then(response => {
      if (response) {
        commit('SET_CHARGE_TICKETS', response?.data?.list);
        return response?.data?.list;
      }
    });
  },
  getPaymentTickets({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return http.get(`/event/${subdomain}/child/${directory}/tickets/payment`, { params }).then(response => {
      if (response) {
        commit('SET_PAYMENT_TICKETS', response?.data);
        return response?.data;
      }
    });
  },
  async getReleaseOtpList({ commit }, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    await http.get(`event/${subdomain}/child/${directory}/ticket/release_otps`, {
      params: {
        ...payload,
        directory: directory,
      }
    }).then((response) => {
      if (response) {
        const { list, count } = response.data;
        commit('SET_RELEASE_OTP_LIST', list);
        commit('SET_RELEASE_OTP_COUNT', count);
      }
    });
  },
  async createReleaseOtp(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(`event/${subdomain}/child/${directory}/ticket/release_otps`, {
      ...payload.data,
      hasOTP: true,
    }).then((response) => response?.data);
  },
  async deleteReleaseOtp(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.delete(`/event/${subdomain}/child/${directory}/ticket/release_otps`, {
      data: {
        ...payload
      }
    }).then((response) => response?.data);
  },
  async getOtpList({ commit }, payload) {
    if(!payload.releaseOtpId) return;
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    await http.get(`event/${subdomain}/child/${directory}/ticket/release_otp/${payload.releaseOtpId}`, {
      params: {
        ...payload,
        directory: directory,
      }
    }).then((response) => {
      if (response) {
        const { list, count, inactiveCount } = response.data;
        commit('SET_OTP_LIST', list);
        commit('SET_OTP_COUNT', count);
        commit('SET_OTP_INACTIVE_COUNT', inactiveCount);
      }
    });
  },
  async disableOtp(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(
      `event/${subdomain}/child/${directory}/ticket/otps/status`, {
        ...payload,
        directory: directory,
      }
    );
  },
  async downloadReleaseOtpList(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return await http.post(`event/${subdomain}/child/${directory}/otps/export`, payload)
      .then((response) => response?.data)
  },
  async getCurrencyCategoriesList({ commit }, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    await http.get(`event/${subdomain}/child/${directory}/currency/category`, payload).then((response) => {
      if (response) {
        const { list, count } = response.data;
        commit('SET_CATEGORIES_LIST', list);
        commit('SET_CATEGORY_COUNT', count);
      }
    });
  },
  async createCurrencyCategory(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(`/event/${subdomain}/child/${directory}/currency/category`, payload, {
      name: payload.name
    }).then((response) => response?.data);
  },
  async updateCurrencyCategory(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(`/event/${subdomain}/child/${directory}/currency/category/${payload.categoryId}`, { name: payload.categoryName })
      .then((response) => response?.data);
  },
  async deleteCurrencyCategory(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.delete(`/event/${subdomain}/child/${directory}/currency/category/${payload.categoryId}`)
      .then((response) => response?.data);
  },
  sortCurrencyCategory(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(`event/${subdomain}/child/${directory}/currency/sort/category`, payload.data);
  },
  getCurrencyQrList({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return http.get(`/event/${subdomain}/child/${directory}/currency/qrcode`, { params }).then(response => {
      if (response) {
        const { list, count } = response.data;
        commit('SET_QR_TICKET_LIST', list);
        commit('SET_QR_TICKET_COUNT', count);
        return response?.data;
      }
    });
  },
  getCurrencyQRDetail({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return http.get(`/event/${subdomain}/child/${directory}/currency/qrcode/${params.id}`).then(response => {
      if (response) {
        commit('SET_TICKET_QR_DETAIL', response.data);
        return response.data;
      }
    });
  },
  async getCurrencyIssuerQrCodeList({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return await http.get(`/event/${subdomain}/child/${directory}/currency/issuer/qrcode`, { params }).then(response => {
      if (response) {
        commit('SET_ISSUER_QR_CODE_LIST', response.data?.data);
      }
    });
  },
  getAllCurrencyQrList({ commit }, params) {
    const { subdomain, directory } = getSubdomainAndDirectory(params);
    return http.get(`/event/${subdomain}/child/${directory}/release_otps/qrcode`, { params }).then(response => {
      if (response) {
        commit('SET_ALL_QR_TICKET_LIST', (response.data?.data || []));
        commit('SET_ALL_QR_TICKET_COUNT', (response.data?.data?.length || 0));
        return response?.data?.data;
      }
    });
  },
  async getCurrencyReleaseOtpList({ commit }, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    await http.get(`event/${subdomain}/child/${directory}/currency/release_otps`, {
      params: {
        ...payload,
        directory: directory,
      }
    }).then((response) => {
      if (response) {
        const { list, count } = response.data;
        commit('SET_RELEASE_OTP_LIST', list);
        commit('SET_RELEASE_OTP_COUNT', count);
      }
    });
  },
  async createCurrencyReleaseOtp(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(`event/${subdomain}/child/${directory}/currency/release_otps`, {
      ...payload.data,
      hasOTP: true,
    }).then((response) => response?.data);
  },
  async deleteCurrencyReleaseOtp(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.delete(`/event/${subdomain}/child/${directory}/currency/release_otps`, {
      data: {
        ...payload
      }
    }).then((response) => response?.data);
  },
  async getCurrencyOtpList({ commit }, payload) {
    if(!payload.releaseOtpId) return;
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    await http.get(`event/${subdomain}/child/${directory}/currency/release_otp/${payload.releaseOtpId}`, {
      params: {
        ...payload,
        directory: directory,
      }
    }).then((response) => {
      if (response) {
        const { list, count, inactiveCount } = response.data;
        commit('SET_OTP_LIST', list);
        commit('SET_OTP_COUNT', count);
        commit('SET_OTP_INACTIVE_COUNT', inactiveCount);
      }
    });
  },
  async disableCurrencyOtp(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(
      `event/${subdomain}/child/${directory}/currency/otps/status`, {
        ...payload,
        directory: directory,
      }
    );
  },
  createCurrencyQr(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(`/event/${subdomain}/child/${directory}/currency/qrcode`, payload, { isMessageConverted: true }).then(response => response?.data?.id);
  },
  updateCurrencyQr(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(`/event/${subdomain}/child/${directory}/currency/qrcode/${payload.id}`, payload, { isMessageConverted: true }).then(response => response?.data?.id);
  },
  removeCurrencyQRs(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.delete(`/event/${subdomain}/child/${directory}/currency/qrcodes`, {
      data: {
        ...payload
      }
    });
  },
  updateCurrencyQrStatus(_, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    return http.post(
      `event/${subdomain}/child/${directory}/currency/status/${payload.id}/qrcode`,
      payload.data
    );
  },
  async getAmountStoreList({ commit }, payload) {
    const { subdomain, directory } = getSubdomainAndDirectory(payload);
    await http.get(`event/${subdomain}/child/${directory}/customer/${payload.number}/account`, payload).then((response) => {
      if (response) {
        commit('SET_AMOUNT_STORE_LIST', response.data);
      }
    });
  },
}
