export const LABEL_MAP = {
  nameF: '[姓]',
  nameL: '[名]',
  nameFK: '[セイ]',
  nameLK: '[メイ]',
  zipcode: '郵便番号',
  telephone: '電話番号',
  clientId: 'ユーザーID',
  clientSecret: 'パスワード',
  email: 'ユーザーID',
  repeatEmail: '確認用メールアドレス',
  password: 'パスワード',
  repeatPassword: '確認用パスワード',
  coupon: {
    name: 'クーポンタイトル',
    description: 'クーポン説明',
    totalUse: '発行枚数管理',
    shopIds: '対象店舗',
    typeCoupon: 'クーポンタイプ',
    limit: '顧客利用制限数'
  },
  catchcopy: {
    text: '表示テキスト',
    color: '背景色',
  },
  expirationDate: {
    startDate: '利用開始日時',
    endDate: '利用終了日時',
  },
  details: {
    title: '項目名',
    content: '内容',
  },
  gallery: {
    categoryName: 'カテゴリー名',
    categoryId: 'カテゴリー名',
    imageId: '写真をアップロード',
    comment: 'コメント',
  },
  menu: {
    categoryName: 'カテゴリー名',
    categoryId: 'カテゴリー名',
    image: '写真をアップロード',
    name: 'メニュー名',
    comment: 'コメント',
    price: '料金表示',
    description: '料金表示の上段',
    cost: '料金表示の下段',
  },
  user: {
    nameSei: 'ユーザー名（姓）',
    nameMei: 'ユーザー名（名）',
    email: 'メールアドレス / ID',
    password: '初回パスワード',
    companyId: '登録済みの事務局/企業',
    eventName: 'イベント名',
    shopId: '店舗名',
  },
  company: {
    name: '事務局/企業名',
    prefectureId: '都道府県',
    municipalityId: '市区群町村',
    zipcode: '郵便番号',
    streetAddress: '丁番地',
    telephone: '電話番号',
    buildingName: '建物名・号室',
  },
  parentEvent: {
    name: '親イベント名',
    subdomain: 'サブドメイン',
    clients: '事務局名/企業名',
    otherClients: '準事務局名/子企業名',
    mapsApiKey: 'Google map APIキー',
    cancelDateNumber: '支払キャンセル可能期間',
    cpmChargeRate: 'CPM購入（付与）時の割増率',
    urlFaqParent: '事務局/親企業用FAQのURL',
    urlFaqChild: '準事務局/子企業用FAQのURL',
    urlFaqStore: '加盟店/店舗グループ用FAQのURL',
    urlFaqCustomer: 'イベント終了時のURL',
    partnerIdentity: '本人確認連携先',
    hostIdentity: 'ホスト（host）',
    realm: 'レルム（realm）',
    clientIdIdentity: 'クライアントID',
    passClientIdentity: 'クライアントシークレット',
    typeEndPointDigitalIdentity: '接続先',
    clientIdDigitalIdentity: 'クライアントID',
    smsToken: 'APIトークン（Authorization）',
    useSwitchCPMCard: 'CPMカード切り替え機能',
    descriptionRegister: '新規会員登録時説明文',
    descriptionMyPage: 'マイページ説明文',
    expirationIdentityDays: '本人確認有効期限',
    useLoginSms: 'SMS会員登録・携帯電話番号ログイン',
    expirationSmsDays: 'SMS認証有効期限',
    useLinkageNecFlag: 'アプリ外部連携',
    necApiAuthKey: 'API KEY',
  },
  event: {
    name: 'イベント名',
    description: 'イベント概要',
    type: 'イベントタイプ',
    chargeChoices: 'チャージ金額の選択肢',
    clients: '事務局名/親企業名',
    directory: 'ディレクトリ名',
    informationUrl: '案内ページURL',
    rate: 'チャージ時の割増率',
    budget: '予算',
    minimumAmount: '1回あたりの購入可能金額の下限',
    minimumUnit: '購入可能単位',
    chargeLimit: '残高に保持できる金額の上限',
    maximumAmount: '1人あたりの累計購入金額上限',
    privacyPolicyUrl: 'プライバシーポリシーのURL',
    termsUrl: '利用規約のURL',
    lawUrl: '特定商取引法の表記のURL',
    titleAdditionalUrl: '追加URLタイトル',
    additionalUrl: '追加URLのURL',
    urlFaqParent: '事務局/親企業用FAQのURL',
    urlFaqChild: '準事務局/子企業用FAQのURL',
    urlFaqStore: '加盟店/店舗グループ用FAQのURL',
    urlFaqCustomer: 'イベント終了時のURL',
    paymentCycles: '支払いサイクル',
    cardImage: 'カードデザインを選択',
    groups: '',
    choiceChargeType: '支払のみタイプでないタイプにした場合はチャージ方法',
    sendMailAfterDaysCharge: '有効期限案内',
    sendMailExpireAfterDays: '有効期限案内',
    typeMomentIdentity: '会員登録時本人確認必須要否',
    keywordIdentification: '登録許可地域キーワード',
    tickets: '',
    daysAllowCancelCharge: 'キャンセル可能期間',
    settingVerify: '認証',
    registerDisp1: '事務局/企業/サービス名称',
    registerDisp11: 'お問い合わせ先',
    registerDisp12: 'お問い合わせ先電話番号',
  },
  shopForm: {
    type: '加盟店審査フォーム',
    email: '加盟店審査フォーム用の自動メール送信先メールアドレス',
  },
  groups: {
    name: 'イベントグループ名',
    rate: '使用できる割合',
  },
  tickets: {
    number: 'TICKET番号',
    name: 'TICKET名',
    typeDesign: 'TICKETデザイン',
    color: 'TICKET文字色',
    purchaseAmount: '購入金額',
    amountBreakdown: 'TICKET内訳（円）',
    unitBreakdown: 'TICKET内訳（枚）',
    notes: '注釈の登録',
    budgetAmount: 'TICKET発行総額（購入金額）',
    limitPerPerson: '保持できるTICKET所持枚数上限',
    limitPerCharge: '同一顧客の購入口数上限',
  },
  notification: {
    message: '本文',
  },
  publicDate: {
    startDate: '掲載開始日',
    endDate: '掲載終了日',
  },
  target: {
    event: '対象イベント',
  },
  shop: {
    nameSei: 'ユーザー名（姓）',
    nameMei: 'ユーザー名（名）',
    email: 'メールアドレス / ID',
    company: '企業名',
    zipcode: '郵便番号',
    prefectureId: '都道府県',
    municipalityId: '市区町村',
    streetAddress: '丁番地',
    buildingName: '建物名・号室',
    telephone: '電話番号',
    name: '店舗名',
    genre: 'ジャンル',
    categories: 'カテゴリー',
    paymentCycle: '支払いサイクル',
    eventGroupId: 'イベントグループ',
    remarks: '備考',
  },
  accountCompany: {
    name: '事務局/企業名',
    company: '企業名',
    zipcode: '郵便番号',
    prefectureId: '都道府県',
    municipalityId: '市区町村',
    streetAddress: '丁番地',
    buildingName: '建物名・号室',
    telephone: '電話番号',
    genre: 'ジャンル',
    categories: 'カテゴリー',
  },
  customer: {
    nameSei: 'お名前（姓）',
    nameMei: 'お名前（名）',
    kanaSei: 'フリガナ（セイ）',
    kanaMei: 'フリガナ（メイ）',
    birthday: '生年月日',
    gender: '性別',
    zipcode: '郵便番号',
    telephone: '電話番号',
    email: 'メールアドレス / ID',
    password: 'ログインパスワード',
  },
  bank: {
    bankCode: '金融機関コード',
    bankName: '金融機関名',
    branchCode: '支店コード',
    branchName: '支店名',
    type: '預金種目',
    number: '口座番号',
    name: '口座名義',
  },
  infor: {
    nameSei: 'ユーザー名（姓）',
    nameMei: 'ユーザー名（名）',
    email: 'メールアドレス / ID',
    password: '現在のパスワード',
    newPassword: '新しいパスワード',
    confirmPassword: '確認用のパスワード',
  },
  inforGroup: {
    nameSei: 'グループ名',
    email: 'メールアドレス / ID',
    password: '現在のパスワード',
    newPassword: '新しいパスワード',
    confirmPassword: '確認用のパスワード',
  },
  inforCpm: {
    nameSei: 'ユーザー名（姓）',
    nameMei: 'ユーザー名（名）',
    cpmUserId: 'ID',
    password: '初回パスワード',
  },
  useAuthentication: {
    key: 'APIキー',
    checkApi: '認証API',
    invalidApi: '登録ID無効化API',
  },
  vcn: {
    siteId: 'サイトID',
    sitePass: 'サイトパスワード',
    shopId: 'ショップID',
    shopPass: 'ショップパスワード',
    companyId: 'companyId（会社識別ID）',
    rcnAlias: 'rcnAlias（RCNエイリアス）',
    templateId: 'templateId（テンプレートID）',
    supplierId: 'supplierId（サプライヤーID）'
  },
  multiPaymentKey: {
    siteId: 'サイトID',
    sitePass: 'サイトパスワード',
    shopId: 'ショップID',
    shopPass: 'ショップパスワード',
  },
  topic: {
    templateImageId: 'お知らせ画像の選択',
    subject: 'タイトル',
    body: '本文',
  },
  judge: {
    status: 'ステータス',
    note: '保留理由',
  },
  usablePeriod: {
    start: '使用可能期間開始',
    end: '使用可能期間終了',
  },
  chargeablePeriod: {
    start: 'チャージ可能期間開始',
    end: 'チャージ可能期間終了',
  },
  paymentablePeriod: {
    start: '支払可能期間開始',
    end: '支払可能期間終了',
  },
  registerDisp13: {
    start: 'お問い合わせ先受付開始時間',
    end: 'お問い合わせ先受付終了時間',
  },
  creditcard: {
    use: 'チャージ方法',
    cards: '使用できるカードブランド',
  },
  convenienceStore: {
    stores: '使用できるコンビニ',
    use: 'チャージ方法',
  },
  maruPay: {
    use: 'チャージ方法',
    bankCodes: '登録された金融機関コード',
  },
  sns: {
    site: 'ホームページ',
    email: 'メールアドレス',
    facebook: 'Facebook',
    twitter: 'X（Twitter）',
    instagram: 'Instagram',
    line: 'LINE',
  },
  shopSetting: {
    receptions: '受付メールアドレス',
    images: '店舗画像',
    zipcode: '郵便番号',
    prefectureId: '都道府県',
    municipalityId: '市区町村',
    streetAddress: '丁番地',
    buildingName: '建物名・号室',
    telephone: '電話番号',
    onTime: '営業時間',
    holiday: '定休日',
    subTitle: 'サブタイトル',
    description: '紹介文',
    access: 'アクセス',
    latlng: '地図',
    title: '項目名',
    content: '内容',
  },
  setPassword: {
    current: '現在のパスワード',
    new: '新しいパスワード',
    confirm: '再入力してください',
  },
  setCode: {
    code: '確認コード',
  },
  group: {
    name: 'グループ名',
    email: 'メールアドレス / ID',
    password: '初回パスワード',
  },
  currency: {
    type: '種別',
    isChecked: 'TICKET',
    chargeQuantity: 'TICKET口数',
    paymentQuantity: 'TICKET枚数',
    amount: '金額',
    added: '金額',
    shopId: '店舗',
    clientId: '子企業'
  },
  events: {
    paymentCycle: '支払いサイクル',
    group: 'グループ',
  },
  pointParentEvent: {
    timingDate: '',
    sendMailExpireAfterDays: 'ポイントの有効期限案内',
    limitPointAdd: '発行ポイント上限',
    limitPointAddPerPerson: '1人あたりの発行上限',
    chargeSpecialTimePercent: '購入時付与ポイント',
    chargeSpecialTimeCpmPercent: 'CPMユーザー付与率',
    chargeSpecialTimeManualPercent: '通貨管理付与率',
    limitPointPaymentSpecial: '発行ポイント上限',
    limitPointPaymentPerPerson: '1人あたりの発行上限',
    paymentSpecialTimePercent: '特別期間限定支払時付与ポイント',
    paymentSpecialTimeCpmPercent: 'CPMユーザー付与率',
    paymentSpecialTimeManualPercent: '通貨管理付与率',
  },
  pointChildEvent: {
    timingDate: '',
    sendMailExpireAfterDays: 'ポイントの有効期限案内',
    limitPointAdd: '発行ポイント上限',
    limitPointAddPerPerson: '1人あたりの発行上限',
    chargeSpecialTimePercent: '購入時付与ポイント',
    chargeSpecialTimeCpmPercent: 'CPMユーザー付与率',
    chargeSpecialTimeManualPercent: '通貨管理付与率',
    limitPointPaymentSpecial: '発行ポイント上限',
    limitPointPaymentPerPerson: '1人あたりの発行上限',
    paymentSpecialTimePercent: '特別期間限定支払時付与ポイント',
    paymentSpecialTimeCpmPercent: 'CPMユーザー付与率',
    paymentSpecialTimeManualPercent: '通貨管理付与率',
  },
  formCategory: {
    categoryName: 'カテゴリー名'
  },
  pointStoreEvent: {
    pointStore: ''
  },
  customerCpm: {
    amount: '発行数'
  },
  pointManual: {
    type: '種別',
    amount: 'ポイント',
    added: 'ポイント',
    shopId: '店舗',
    cancelShopId: '店舗'
  },
  pointQR: {
    pointCategoryId: 'ポイントカテゴリー',
    title: '管理用タイトル',
    shopId: '発行元店舗',
    type: '発行ポイント数',
    amount: 'ポイント',
    numberTimesLimit: '1QR当り利用制限',
    quantity: '利用可能枚数の設定',
    keyword: '認証キーワードの設定',
  },
  ticketQR: {
    categoryId: 'TICKETカテゴリー',
    categoryCurrencyId: 'QRカテゴリー',
    name: 'QRコード名称',
    issuer: '発行元',
    type: '発行TICKET',
    currencyType: '発行QR',
    amount: '金額',
    isChecked: 'TICKET',
    quantity: '口数/枚数',
    numberTimesLimit: '1QR当り利用制限',
    totalLimit: '利用可能回数',
    setExpiration: '有効期限',
  },
  useDate: {
    start: '利用可能の期間設定（から）',
    end: '利用可能の期間設定(まで）',
  },
  useTicketDate: {
    start: ' 利用可能の期間設定の開始日',
    end: '利用可能の期間設定の終了日',
  },
  chargeDate: {
    start: '',
    end: '',
  },
  timeDate: {
    start: '利用開始日時',
    end: '利用終了日時',
  },
  paymentTimeDate: {
    start: '利用開始日時',
    end: '利用終了日時',
  },
  template: {
    name: 'テンプレート名',
    title: 'クーポンタイトル',
    content: '特典内容',
    preCondition: '提示条件',
    useCondition: '利用条件',
    images: 'クーポン画像',
    expirationType: '期限',
    pushMesssage: 'プッシュ通知タイトル',
  },
  news: {
    name: 'テンプレート名',
    title: 'ニュースタイトル',
    content: '本文',
    image: '画像添付',
    pushMessage: 'プッシュ通知タイトル',
  },
  registNotice: {
    type: '配信時期',
    years: '年',
    months: '月',
    days: '日',
    hours: '時',
    templateId: 'テンプレート',
    message: 'タイトル',
  },
  oneTimeToken: {
    qrTicketId: 'QRコード名称',
    number: '件数',
    validDays: '有効期限',
    qrPointId: 'QRコード名称',
  }
};

export const MESSAGE_MAP = {
  alpha: 'は文字のみで入力してください。',
  alphaNum: 'はで文数字のみで入力してください。',
  isAlphanumAndSpecial: 'に半角数字・半角英字を組み合わせ。',
  isAlphanumSpecial: 'は文字数で半角英数字および記号（￥を除く）を組み合わせ。',
  email: 'の形式が不正です。',
  minLength: 'は{{min}}文字以上で入力してください。',
  maxLength: 'は{{max}}文字以内で入力してください。',
  imageMaxLength: 'は{{max}}枚以内にしてください。',
  numberMaxLength: 'は{{max}}数字以内で入力してください。',
  dayMaxLength: 'は{{max}}桁以内で入力してください。',
  optionMaxLength: 'は{{max}}つまでにご設定してください。',
  required: 'を入力してください。',
  isNotEmpty: 'を入力してください。',
  confirmPasswordRequired: 'の入力部分に入力してください。',
  selectRequired: 'を選択してください。',
  between: 'は{{min}}から{{max}}までの数字を入力してください。',
  oneTimeTokenBetween: "件数入力は{{min}}～{{max}}の範囲で入力してください。",
  validDaysBetween: "有効期限は{{min}}～{{max}}の範囲で入力してください。",
  minAmount: 'は{{min}}から最大金額までの数字を入力してください。',
  minAmountPoint: 'は{{min}}から最大ポイントまでの数字を入力してください。',
  onlyKana: 'はカタカナで入力してください。',
  sameAsPassword: 'はパスワードと一致しません。',
  sameAsNewPassword: 'の入力部分は新しいパスワードと一致しません。',
  sameAsEmail: 'はメールアドレスと一致しません。',
  isPhoneNumber: 'は0から始まる10～11桁の数字で入力してください。',
  isUrl: 'に正しい形式を入力してください。注）日本語、全角不可',
  isZipcode: 'はハイフン無しの7桁の半角数字を入力してください。',
  minValue: 'は{{min}}からの整数を入力してください。',
  lessBudgetValue: '予算は「{{label}}」より下回らない金額を入力してください。',
  isFacebookUrl: 'に正しい形式を入力してください。',
  isTwitterUrl: 'に正しい形式を入力してください。',
  isInstagramUrl: 'に正しい形式を入力してください。',
  isLineUrl: 'に正しい形式を入力してください。',
  isBankCode: 'を正しい形式にて入力してください。',
  isBankName: 'に不正な文字が含まれています。',
  isBranchCode: 'を正しい形式にて入力してください。',
  isBankNumber: 'に正しい形式を入力してください。',
  alphanumSpecialWithMinLength:
    'は{{min}}文字以上で半角英数字および記号（/ \\ ¥ を除く）を全て組み合わせてご入力ください。',
  alphanumSpecialWithMinMaxLength:
    'が{{min}}文字以上{{max}}文字以内で半角英数字および記号(¥を除く)を全て組み合わせてご入力ください。',
  alphanumAndSpecialWithMinMaxLength:
    'は￥を含まない{{min}}～{{max}}文字の半角英数字記号で入力してください。（半角数字・半角英字を組み合わせる必要あります。）',
  alphanumAndSpecialWithMinMaxLengthPassword:
    'は半角英数字を組み合わせ、{{min}}～{{max}}文字で設定してください。\nまた、半角記号（/ \\ ¥ を除く）を含めても構いません。',
  alphanumWithMaxLength: 'は半角英数字{{max}}文字以内で入力してください。',
  alphaHiraganaWithMinMaxLength: 'は{{min}}文字以上{{max}}文字までのひらがなで入力してください。',
  duplicateGroupName:
    '1つ目のイベントグループ名と2つ目のイベントグループ名は重複しない名前を指定してください。',
  updatedMinValue: 'より小さい値には変更できません。',
  notConsecutiveDashes: 'ハイフンは連続して使用することもできません。',
  isNumberLowercaseDash: 'は数字、半角英小文字、ハイフンのみを入力してください。',
  notSpace: 'にスペースを含まない文字で入力してください。',
  limitWithMinMaxValue: 'は{{min}}～{{max}}の範囲で入力してください。',
  limitDateWithMinMaxValue: '{{min}}～{{max}}日間までの範囲で入力してください。',
  notLimitMoreThanRemaining: 'は発行枚数管理の件数より大きな数字を登録することはできません。',
  notRegistDateTime: '利用期間のないクーポンは登録することができません。',
  remainingMoreThanLimit: '発行枚数は顧客利用制限数の件数より小さな数字を登録することはできません。',
  remainingMoreThanTotalUsed: 'は利用回数より大きな数字を入力してください。',
  minMaxPercent: '{{min}}～{{max}}%の範囲で入力してください。',
  pointRequired: '%追加に値を入力してください。',
  noSelectedItem: '項目が選択されていません。',
  timingDateWithMaxValue: 'ポイント加算タイミングがキャンセル可能期間よりも短いため設定することができません。',
  numberTimesLimitWithMaxValue: 'は利用可能枚数より大きな数字を登録することはできません。',
  numberLimitWithMaxValue: 'は利用可能回数より大きな数字を登録することはできません。',
  totalLimitWithMaxValue: 'は1QR当り利用制限より小さな数字を登録することはできません。',
  quantityMoreThanTotalUsed: 'は利用回数より小さな数字を登録することはできません。',
  dateWithMinMaxValue: '{{min}}～{{max}}日までの範囲で入力してください。',
  minValueCoupon: 'は{{min}}からの整数を入力してください。',
  validPeriodPoint: 'はポイントの有効期限が空欄の場合無効です。',
  notPeriodPoint: '入力した日付では登録ができません。',
  numberAndAlphabetSpecialHalfsize: 'は半角英数字記号で入力してください',
  validMailAfterDaysCharge: '入力した日付では登録ができません。',
  characterWithMinMaxLength: 'は{{min}}～{{max}}文字の範囲で入力してください。',
  characterWithMinLength: 'は{{min}}文字で入力してください。',
  duplicateCardNumber: '同一のTICKET番号での登録はできません。',
  duplicateCardName: '同一のTICKET名での登録はできません。',
  isMultipleNumber: 'は購入金額の倍数で入力してください。',
  isLimitWithValidValue: 'はTICKET発行総額（購入金額）以上に設定することはできません。',
  isLimitWithValidBudget: 'はTICKET所持枚数上限以上に設定することはできません。',
  notMoreThanPointAdd: 'は発行ポイント上限以上に設定することはできません。',
  limitWithPointAdd: 'より小さい値には設定することはできません。',
  limitWithPointAddTotal: 'に達したため設定することはできません。',
  notSameAsDateTime: '利用期間のない購入時付与は登録することができません。',
  notSameAsPaymentDateTime: '利用期間のない支払時付与は登録することができません。',
  isPointWithMinMaxValue: '{{min}}～999,999ポイントの範囲で入力してください。',
  isDuplicateHourMinute: '利用期間のないお問い合わせ先受付時間は登録することができません。',
};

export const FIELD_MAP = {
  customer: '顧客情報',
  status: 'ステータス',
  event: 'イベント',
  parentEvent: '親イベント',
  childEvent: '子イベント',
  eventInfor: '子イベント情報',
  eventStatus: '子イベントの状態',
  accountInfo: 'アカウント情報',
  accountUserInfo: 'ユーザー情報',
  account: 'アカウント',
  accountUser: 'ユーザー',
  user: 'ユーザ',
  shop: '加盟店',
  shopInfor: '加盟店情報',
  settingDefault: '店舗設定',
  settingBase: '店舗基本設定',
  settingDetail: '店舗設定',
  newTopic: '新しいお知らせ',
  topic: 'お知らせ',
  notification: '通知',
  template: 'テンプレート',
  newNotification: '新しい通知',
  group: 'グループ',
  groupInfor: 'グループの情報',
  category: 'カテゴリー',
  gallery: '写真を',
  menu: 'メニュー',
  coupon: 'クーポン',
  accountCompany: '企業情報',
  passcode: '支払用パスコード',
  creditcard: 'クレジットカード情報',
  point: 'ポイント設定',
  pointStore: 'ポイント増加%',
  pointCategory: 'カテゴリー',
  pointQR: 'QRコード',
  maruPay: '口座情報',
  oneTimeToken: "ワンタイムトークン",
};

export const COMMON_MESSAGE = {
  createdSuccess: 'を登録しました。',
  updatedSuccess: 'を更新しました。',
  deletedSuccess: 'を削除しました。',
  createdUserSuccess: 'ユーザを作成しました。',
  createdUserInfoSuccess: 'ユーザーを登録しました。',
  sortSuccess: '並び順を更新しました。',
  addShopToGroupSuccess: 'グループに追加しました。',
  disableSuccess: '無効化が完了しました。',
  someoneUpdated: 'すでに他のユーザーに更新されています。',
  removeShopFromGroupSuccess: '指定した加盟店をグループから解除しました。',
  unAuthorizedTable: '閲覧権限がありません。',
  noData: '一致するデータがありません。',
  noClientData: '事務局は作成されていません。',
  overMaxsize: 'MB以内の決済音をアップロードしてください。',
  overMaxsizeImg: 'MB以内のイメージをアップロードしてください。',
  noMatchingAddress: '一致する結果がありません。',
  noSelectedItem: '項目が選択されていません。',
  noSelectedCategory: 'カテゴリー名を入力してください。',
  noSelectedTemplate: 'テンプレート名を選択してください。',
  noSelectedCustomer: '顧客を選択してください。',
  noSelectedShop: '項目は選択されていません。',
  noSelectedCouponCsv: 'CSVアップロードは該当のクーポンをチェックしてから行ってください。',
  noSelectedCouponForIssuer: 'クーポンが選択されていません。',
  noSelectedOpenCoupon: '個別配布タイプのクーポンが存在しません。',
  duplicateCategory: '既に登録されているカテゴリー名と同じ名前での登録はできません。',
  duplicateCurrencyCategory: '既に登録されているカテゴリー名と同じ名前での登録はできません。',
  noCategoryName: 'カテゴリー名を入力してください。',
  categoryNameMaxLength: '店舗カテゴリーは{{max}}文字以内で入力してください。',
  maxCategory: '{{max}}件より多くのカテゴリーを作成できません。',
  onlyNotJudgedItems:
    '「審査待ち」又は「保留」のステータスがある店舗のみに審査処理が行えます。',
  onlyImageFile: 'ファイル名の拡張子を確認してください。',
  onlyCsv: 'インポートできるファイル形式ではありません。',
  unacceptedCharsetCsv: 'CSVファイルの文字コードに問題があります。UTF-8又はSHIFT_SJISに変換の上再度インポートしてください。',
  importCsvStart: 'インポートを開始します。',
  updatedWaitingStatus: '保留にしました。',
  updatedApprovedOrRejectedStatus: '審査結果を反映しました。',
  noUpdatedItem: '該当する店舗がありませんでした。',
  pauseUser: '該当するアカウントの一時停止を解除しました。',
  repauseUser: '該当するユーザーの一時停止を解除しました。',
  activeUser: '該当するアカウントを一時停止しました。',
  reactiveUser: '該当するユーザーを一時停止しました。',
  cancelPayment: '支払いをキャンセルしました。',
  cancelCharge: '購入をキャンセルしました。',
  unblockSuccess: 'ログインロックを解除しました。',
  statusEventRequired:
    '未登録のステータス以外は以下の項目が必須になります。\n．イベント概要。\n．チャージ方法。\n．1回あたりの購入可能金額の下限。\n．カードデザイン。\n．支払いサイクル。\n．プライバシーポリシーのURL。\n．利用規約のURL。\n．GMO-PG情報。',
  deletedBySomeone: 'すでに他のユーザーが削除しました。',
  import_charge_finished_success: '付与終了（すべて付与できました。）',
  import_charge_finished_fail: '付与終了（付与できなかったデータあり。）',
  import_charge_fail: '付与できませんでした。',
  import_payment_finished_success: '利用終了（すべて利用できました。）',
  import_payment_finished_fail: '利用終了（利用できなかったデータあり。）',
  import_payment_fail: '利用できませんでした。',
  import_coupon_distribution_finished_success: '消費終了（すべて消費できました。）',
  import_coupon_distribution_finished_fail: '一部のクーポン配布が失敗しました。詳細はCSVにてご確認ください。',
  import_coupon_distribution_fail: '一部のクーポン配布が失敗しました。詳細はCSVにてご確認ください。',
  import_payment_point_finished_success: '消費終了（すべて消費できました。）',
  import_payment_point_finished_fail: '消費終了（消費できなかったデータあり。）',
  import_payment_point_fail: '消費できませんでした。',
  import_csv_finished_success: 'インポート終了（すべてインポートできた）',
  import_csv_finished_fail: 'インポート終了（インポートできなかったデータあり）',
  import_csv_fail: 'インポートデータのフォーマット、ヘッダレコードをご確認ください。',
  import_csv_duplicate_fail: '重複したインポートデータがあったため処理を行いませんでした。',
  import_currency_csv_fail: 'インポートできませんでした。インポートデータをご確認ください。',
  import_csv_input_fail: '該当するインポートを特定できませんでした',
  import_regist_customer_finished_success: '{{count}}件の顧客を子イベントに登録しました。',
  import_regist_customer_finished_fail: '{{count}}件の顧客を子イベントに登録しました。',
  import_regist_customer_fail: '子イベントに参加していない顧客は存在しませんでした。',
  import_currency_csv_finished_success: 'インポート終了（すべてインポートできた）',
  import_currency_csv_finished_fail: 'インポート終了（インポートできなかったデータあり）',
  import_currency_csv_duplicate_fail: '重複したインポートデータがあったため処理を行いませんでした。',
  importing_csv: 'インポート中',
  noPaymentedOrChargedCurrency: '金額付与又は消費は行われませんでした。',
  noPaymentedOrChargedTicketCurrency: 'TICKET付与又は消費は行われませんでした。',
  noPaymentedOrChargedManualPoint: 'ポイント付与又は消費は行われませんでした。',
  notOpenUseCurrencyFlag: 'グループ分け利用のイベントは管理画面からの金額発行許可をONに指定出来ません。',
  notOpenUsePointAndTotalBalanceFlag: '「総残高表示」と「ポイントを利用する」は同時に設定することはできません。',
  notOpenUsePointAndUseVcn: '「VCN連携」と「ポイントを利用する」は同時に設定することはできません。',
  notOpenUsePointAndUseVcnAndTotalBalanceFlag: '「総残高表示」「VCN連携」と「ポイントを利用する」は同時に設定することはできません。',
  notChangeCommonToOwnEvent: '「全イベント共通」から「子イベント単独」へ変更はできません。',
  notChangeCommonToOwnIfHasPoint: 'ポイント履歴が存在するため、「全イベント共通」から「子イベント単独」へ変更はできません。',
  notChangeCommonToOwnIfNoHasPoint: 'ポイントカテゴリーが存在するため、「全イベント共通」から「子イベント単独」へ変更はできません。',
  notChangeWhenOnPublicFunction: 'ポイント機能を公開するがONのため変更することができません。',
  notChangeOnWhenAtPeriodTransfer: '切り替え最中のため、ポイント機能を公開することができません。',
  notOnPublicFunction: '付与予定ポイントが存在していますのでONにすることはできません。',
  hasGrantedPoint: '付与予定ポイントが存在していますので変更することができません。',
  onlySettingWithMaster: 'マスタ管理画面からのみON/OFFの設定が出来ます。',
  notEditUseGroupFlag: 'イベントグループ分け利用は新規作成時のみ指定可能となります。',
  notEditParentEventVcn: '顧客情報が登録された場合は、VCN連携の情報が変更できません。',
  notEditChildEventVcn: '親イベントの「VCN連携」がOFFの時は子イベントの「VCN連携を利用する」の設定が出来ません。',
  notEditShopSettingInitial: '不正なアクセスです。',
  notEditShopSettingGallery: 'gallery',
  notEditShopSettingMenu: 'menu',
  notChangeOffIfCommonAllEvent: '全イベント共通になっているため、ポイント利用をOFFに変更することができません。',
  notChangeOffUsePointFlag: 'ポイント利用をONからOFFに変更することができません。',
  formPointRequired: '必須項目が選択されていません。',
  invalidPercent: '0.1～999.9%の範囲で入力してください。',
  invalidTextboxPoint: '1～{{max}}ポイントの範囲で入力してください。',
  invalidCalendar: '日付の範囲を指定してください。',
  outOfLoginSession: 'セッションの有効期限が切れています。または、別の端末でログインされています。',
  notOffToggleEvent: '利用するイベントをONからOFFに変更することができません。',
  notOffToggleUsedClientCoupon: '顧客利用制限は変更することができません。',
  notOffToggleRemainingCoupon: '発行枚数管理は変更することができません。',
  noCountCouponCsv: 'クーポン配布は行われませんでした。',
  invalidPointStore: 'ポイント増加%に値を入力してください。',
  pointCreatedSuccess: '店舗にポイント増加設定を行いました。',
  pointCommonCreatedSuccess: '{{count}}件の店舗にポイント増加設定を行いました。',
  notChargeOrPaymentPoint: 'ポイント機能が非公開のため、付与や消費することはできません。',
  notEditUsedCoupon: '利用回数が発行枚数に達したため、編集することはできません。',
  notOnDisplayShopMenuFront: '加盟店機能OFF時のフロント画面表示はギャラリー、メニュー、クーポンいずれかOFFの場合のみ設定可能です。',
  notChangeOnMonitoringAndUiFlag: '「みまもり設定」と「ポイントUIを変更する」は同時に設定することはできません。',
  notChangeWhenOnAuthEmailFlag: 'メール認証設定はOFFからONに変更することができません。',
  numberAndAlphabetHalfsize: '半角数字のみ入力可能',
  isNumberHalfsize: '半角数字のみ入力可能',
  notOpenUseCurrencyFlagWithCPM: '',
  giftCardWithMaxQuantity: 'TICKET番号は1~20の範囲で入力してください。',
  giftCardRequired: 'TICKET番号（{{index}}）番号を入力してください。',
  notUseTicketAndBalanceFlag: '「総残高表示」と「TICKETタイプを利用する」は同時に設定することはできません。',
  notUseTicketAndCpmFlag: '「CPM利用」と「TICKETタイプを利用する」は同時に設定することはできません。',
  notUseTicketAndPointFlag: '「ポイントを利用する」と「TICKETタイプを利用する」は同時に設定することはできません。',
  notUseTicketPointAndCpmFlag: '「CPM利用」「ポイントを利用する」と「TICKETタイプを利用する」は同時に設定することはできません。',
  notUseTicketFlagMoreThanNumberChildEvent: 'TICKETタイプ以外の子イベントが存在しているためONにすることはできません。',
  notUseTicketIfOnCpmAndBalance: '「総残高表示」「CPM利用」と「TICKETタイプを利用する」は同時に設定することはできません。',
  notOffTicketFlagIfHasTicketType: 'TICKETタイプの子イベントが存在しているためOFFにすることはできません。',
  notDeleteTicketCardIfUsed: '該当のTICKETを保持している顧客がいるため削除はできません。',
  notDeleteTicketCardIfNotStatus: '一時停止中・未登録・公開準備中のイベント以外が削除できません。',
  notEditBtnValidFlag: '一時停止中・未登録・公開準備中のイベント以外が編集できません。',
  copyUrlSuccess: 'URLをコピーしました。',
  formDateRequired: '年月日時を選択してください。',
  notOffSms: '子イベントにてSMS認証連携を利用するがONのため、SMS認証連携をOFFにすることができません。',
  notOffIdentity: '子イベントにて本人確認連携を利用するがONのため、本人確認連携をOFFにすることができません。',
  invalidChargePoint: '購入時付与と特別期間限定購入時付与は同時に設定することはできません。',
  limitWithPointAddPerson: '1人あたりの発行上限は発行ポイント上限以上に設定することはできません。',
  invalidPaymentPoint: '支払時付与と特別期間限定支払時付与は同時に設定することはできません。',
  creditCardSuccess: 'クレジットカード情報削除が完了しました。',
  maruPaySuccess: '口座情報削除が完了しました。',
  notChangeCpmOff: 'CPMカード切り替え機能をありからなしに変更することはできません。',
  notChangeYesOff: 'SMS会員登録・携帯電話番号ログインをありからなしに変更することはできません。',
  notChangeEmailOff: 'SMS会員登録・携帯電話番号ログインを会員登録必須からなしに変更することはできません。',
  notChangePhoneOff: 'SMS会員登録・携帯電話番号ログインを会員登録ログイン必須からなしに変更することはできません。',
  notChangeSmsOff: 'SMS会員登録・携帯電話番号ログインがONのため、SMS認証連携を利用するをOFFで登録することはできません。',
  notOnUseKeywordAndOtp: 'ワンタイムトークンの利用と認証キーワードの設定は同時に設定することはできません。',
  notChangeKeywordToOtp: '認証キーワードの設定を設定していた場合、ワンタイムトークンの利用に変更することはできません。',
  notChangeOtpToKeyword: 'ワンタイムトークンの利用を設定していた場合、認証キーワードの設定に変更することはできません。',
  changeToggleSuccess: '支払用パスコードを設定しました。',
  notOffLinkageNecVcn: 'アプリ外部連携とVCN連携は同時に設定することはできません。',
  notOffLinkageNecOmise: 'アプリ外部連携とGMOおみせアプリ連携は同時に設定することはできません。',
  notOffLinkageNecOmiseAndVcn: 'アプリ外部連携とGMOおみせアプリ連携・VCN連携は同時に設定することはできません。',
};
