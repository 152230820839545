export default {
  categoriesList: (state) => state.categoriesList.list,
  categoryCount: (state) => state.categoriesList.count,
  parentPointDetail: (state) => state.parentPointDetail,
  parentPaymentStoreFlag: (_, getters) => {
    return getters.parentPointDetail?.paymentStoreFlag === 1;
  },
  parentPaymentStoreSpecialFlag: (_, getters) => {
    return getters.parentPointDetail?.paymentStoreSpecialFlag === 1;
  },
  hasParentPointDetail: (_, getters) => {
    return getters.parentPointDetail?.id;
  },
  customerList: (state) => state.customerList.list,
  customerCount: (state) => state.customerList.count,
  historyPointList: (state) => state.historyPointList.list,
  historyPointCount: (state) => state.historyPointList.count,
  historyPoint: (state) => state.historyPointList.point,
  historyPlannedPoint: (state) => state.historyPointList.plannedPoint,
  pointQRCodePulldown: (state) => state.pointQRCodePulldown,
  historyPointTypeList: (state) => state.historyPointTypeList.list,
  shopPointList: (state) => state.shopPointList.list,
  pointStoreList: (state) => state.pointStoreList.list,
  pointStoreCount: (state) => state.pointStoreList.count,
  aggregratePointDetail: (state) => state.aggregratePointDetail,
  customerPointActivities: (state) => state.customerPointActivities,
  pointQRList: (state) => state.pointQRList.list,
  pointQRCount: (state) => state.pointQRList.count,
  pointQrDetail: (state) => state.pointQrDetail,
  releaseOtpList: (state) => state.releaseOtpList.list,
  releaseOtpCount: (state) => state.releaseOtpList.count,
  allQrTicketList: (state) => state.allQrTicketList.list,
  allQrTicketCount: (state) => state.allQrTicketList.count,
  otpList: (state) => state.otpList.list,
  otpCount: (state) => state.otpList.count,
  otpInactiveCount: (state) => state.otpList.inactiveCount,
};
