import http from '@/config/api';
import { StatusConstants } from '@/constants/status';
import router from '@/router';

export default {
  async getCustomerTotal({ commit }, params) {
    await http.get(`/event/${params.subdomain}/customers`, { params })
      .then(response => {
        if (response) {
          commit('SET_TOTAL_UNSUBSCRIBE', response.data.totalUnsubscribe);
          commit('SET_HAS_CUSTOMER_TOTAL', true);
          if (params?.type === StatusConstants.customerType.all.value) {
            commit('SET_CUSTOMER_CPM_TOTAL', response.data.count);
          } else {
            commit('SET_CUSTOMER_TOTAL', response.data.count);
          }
        }
      })
  },
  async getCustomerList({ commit, dispatch, getters, rootGetters }, params) {
    if (params.forCoupon && !params.numberLike) {
      return commit('RESET_CUSTOMER_LIST');
    }
    if (['EventUser', 'ShopUser'].includes(router.history.current.name)) dispatch('event/getParentEventDetail', params.subdomain, { root: true });
    if (params?.isActiveCpm === '') delete params.isActiveCpm;
    await http.get(`/event/${params.subdomain}/customers`, {
      params: {
        ...params,
        ...(
          router.history.current.name === 'EventCurrency' ?
          {
            type: rootGetters['event/isUseCpmChildEvent'] ? StatusConstants.customerType.all.value : StatusConstants.customerType.normal.value
          } : {}
        ),
        ...(
          ['EventParentPointManagement', 'EventChildPointManagement'].includes(router.history.current.name) ? {
            type: StatusConstants.customerType.all.value,
          } : {}
        ),
        ...(
          router.history.current.name === 'EventParentPointManagement' ? { isActiveCpm: [1], isTypePoint: 1 } : {}
        )
      }
    }).then(response => {
        if (response) {
          const { list, count, existsOmiseNumber } = response.data;
          commit('SET_CUSTOMER_LIST', list);
          commit('SET_COUNT_CUSTOMER_LIST', count);
          commit('SET_EXIST_OMISE_NUMBER', existsOmiseNumber);
          if ((response.data.totalUnsubscribe > getters['totalUnsubscribe']) && getters['hasCustomerTotal']) {
            const unscribeUsers = response.data.totalUnsubscribe - getters['totalUnsubscribe'];
            commit('SET_CUSTOMER_CPM_TOTAL', getters['customerCpmTotal'] - unscribeUsers);
            commit('SET_CUSTOMER_TOTAL', getters['customerTotal'] - unscribeUsers);
            commit('SET_TOTAL_UNSUBSCRIBE', response.data.totalUnsubscribe);
          }
        }
      })
  },
  createCustomer(_, payload) {
    return http.post(`/event/${payload.subdomain}/customer`, payload)
  },
  async getCustomerDetail({ commit }, params) {
    await http.get(`/event/${params.subdomain}/customer/${params.customerId}`, { params })
      .then(response => {
        if (response) commit('SET_CUSTOMER_DETAIL', response.data);
      })
  },
  async getCustomerActivities({ commit }, params) {
    await http.get(`/event/${params.subdomain}/customer/${params.customerId}/activities`, { params })
      .then(response => {
        if (response) commit('SET_CUSTOMER_ACTIVITIES', response.data.list);
      })
  },
  updateCustomer({ commit }, payload) {
    return http.post(`/event/${payload.subdomain}/customer/${payload.customerId}`, payload)
      .then((response) => {
        if (response) commit('SET_CUSTOMER_DETAIL', payload);
        return response?.data;
      })
  },
  updateCustomerCpm({ commit }, payload) {
    return http.post(`/event/${payload.subdomain}/customer/cpm/${payload.customerId}`, payload)
      .then((response) => {
        if (response) commit('SET_CUSTOMER_DETAIL', payload);
        return response?.data;
      })
  },
  updateCustomerStatus({ commit }, payload) {
    return http.post(`/event/${payload.subdomain}/customer/${payload.customerId}/status`, payload)
      .then((response) => {
        if (response) commit('UPDATE_CUSTOMER_DETAIL', { validFlag: payload.status });
        return response?.data;
      })
  },
  deleteCreditCard(_, payload) {
    return http.delete(`/event/${payload.subdomain}/child/${payload.directory}/customer/${payload.customerId}/creditcard`)
      .then((response) => response?.data)
  },
  deleteMaruPay(_, payload) {
    return http.delete(`/event/${payload.subdomain}/child/${payload.directory}/customer/${payload.customerId}/bankAccount`)
      .then((response) => response?.data)
  },
  deletePasscode({ commit }, payload) {
    return http.delete(`/event/${payload.subdomain}/customer/${payload.customerId}/passcode`)
      .then((response) => {
        if (response) commit('UPDATE_CUSTOMER_DETAIL', { hasPasscode: false });
        return response?.data;
      })
  },
  deleteCustomer(_, payload) {
    return http.delete(`/event/${payload.subdomain}/customer/${payload.customerId}`);
  },
  async downloadCustomerList(_, params) {
    return await http.post(`/event/${params.subdomain}/customers/export`, params)
      .then((response) => response?.data)
  },
  async createCustomerCpm(_, payload) {
    return await http.post(`/event/${payload.subdomain}/customers/releaseCpm`, payload)
      .then((response) => response?.data)
  },
  async checkDuplicateTel(_, params) {
    return await http.get(`/event/${params.subdomain}/customer/cpm/checkTel/${params.customerId}/${params.telephone}`)
      .then((response) => response?.data)
  },
  async importCpmCsv(_, form) {
    const subdomain = form.subdomain || router.currentRoute.params.subdomain;
    return await http.post(`event/${subdomain}/cpm/import`, form, { isMultipartContent: true });
  },
  async importNormalCsv(_, form) {
    const subdomain = form.subdomain || router.currentRoute.params.subdomain;
    return await http.post(`event/${subdomain}/customer/csv/import`, form, { isMultipartContent: true });
  },
  async getImportStatus(_, id) {
    return await http.get(`import/status/${id}`).then((response) => {
      return response?.data;
    });
  },
  async getTemplatesList({ commit }, params) {
    await http.get(`/event/${params.subdomain}/template/coupons`, { params })
      .then(response => {
        if (response) {
          commit('SET_TEMPLATES_LIST', response.data?.list);
          commit('SET_TEMPLATES_COUNT', response.data?.count);
        }
      })
  },
  async getNewsList({ commit }, params) {
    await http.get(`/event/${params.subdomain}/template/news`, { params })
      .then(response => {
        if (response) {
          commit('SET_NEWS_LIST', response.data?.list);
          commit('SET_NEWS_COUNT', response.data?.count);
        }
      })
  },
  deleteTemplatesList(_, params) {
    const subdomain = params.subdomain || router.currentRoute.params.subdomain;
    return http.delete(`/event/${subdomain}/template/coupons`, { params })
  },
  deleteNewsList(_, params) {
    const subdomain = params.subdomain || router.currentRoute.params.subdomain;
    return http.delete(`/event/${subdomain}/template/news`, { params })
  },
  createTemplate(_, payload) {
    const subdomain = payload.subdomain || router.currentRoute.params.subdomain;
    return http.post(`/event/${subdomain}/template/coupon`, payload).then(response => response?.data?.id);
  },
  updateTemplate(_, payload) {
    const subdomain = payload.subdomain || router.currentRoute.params.subdomain;
    return http.post(`/event/${subdomain}/template/coupon/${payload.id}`, payload).then(response => response?.data?.id);
  },
  async getTemplateDetail({ commit }, payload) {
    await http.get(`/event/${payload.subdomain}/template/coupon/${payload.id}`)
      .then(response => {
        if (response) commit('SET_TEMPLATE_DETAIL', response.data);
      })
  },
  createNews(_, payload) {
    const subdomain = payload.subdomain || router.currentRoute.params.subdomain;
    return http.post(`/event/${subdomain}/template/new`, payload).then(response => response?.data?.id);
  },
  updateNews(_, payload) {
    const subdomain = payload.subdomain || router.currentRoute.params.subdomain;
    return http.post(`/event/${subdomain}/template/new/${payload.id}`, payload).then(response => response?.data?.id);
  },
  async getNewsDetail({ commit }, payload) {
    await http.get(`/event/${payload.subdomain}/template/new/${payload.id}`)
      .then(response => {
        const data = response.data;
        const imageData = [
          { id: null, order: 0, url: null },
          { id: null, order: 1, url: null },
          { id: null, order: 2, url: null },
          { id: null, order: 3, url: null },
        ];
        data.image = (imageData || []).map((e, index) => {
          return {
            id: data.image?.[index]?.id || null,
            url: data.image?.[index]?.url || null,
            order: e.order,
          }
        });
        if (response) commit('SET_NEWS_DETAIL', data);
      })
  },
  async getChildList({ commit }, params) {
    await http.get(`/event/${params.subdomain}/list/child`, { params })
      .then(response => {
        if (response) {
          if (response) commit('SET_CHILD_LIST', response.data);
        }
      })
  },
  pushNotice(_, payload) {
    const subdomain = payload.subdomain || router.currentRoute.params.subdomain;
    return http.post(`/event/${subdomain}/omise/notices`, payload);
  },
  pushCoupons(_, payload) {
    const subdomain = payload.subdomain || router.currentRoute.params.subdomain;
    return http.post(`/event/${subdomain}/omise/coupons`, payload);
  },
  pushNews(_, payload) {
    const subdomain = payload.subdomain || router.currentRoute.params.subdomain;
    return http.post(`/event/${subdomain}/omise/news`, payload);
  },
  async updateUsePaymentPasscode(_, params) {
    return await http.post(`/event/${params.subdomain}/customer/passcode/${params.customerId}`, params)
      .then((response) => response?.data)
  },
  unlockLoginCustomer({ commit }, payload) {
    return http.post(`/event/${payload.subdomain}/customer/${payload.customerId}/unblock`, payload)
      .then((response) => {
        if (response) commit('UPDATE_CUSTOMER_DETAIL');
        return response?.data;
      })
  },
}
