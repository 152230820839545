export default {
  categoriesList: (state) => state.categoriesList.list,
  categoryCount: (state) => state.categoriesList.count,
  childPointDetail: (state) => state.childPointDetail,
  childPaymentStoreFlag: (_, getters) => {
    return getters.childPointDetail?.paymentStoreFlag === 1;
  },
  childPaymentStoreSpecialFlag: (_, getters) => {
    return getters.childPointDetail?.paymentStoreSpecialFlag === 1;
  },
  customerList: (state) => state.customerList.list,
  customerCount: (state) => state.customerList.count,
  historyPointList: (state) => state.historyPointList.list,
  historyPointCount: (state) => state.historyPointList.count,
  historyPoint: (state) => state.historyPointList.point,
  historyPlannedPoint: (state) => state.historyPointList.plannedPoint,
  hasChildPointDetail: (_, getters) => {
    return getters.childPointDetail?.id;
  },
  pointStoreList: (state) => state.pointStoreList.list,
  pointStoreCount: (state) => state.pointStoreList.count,
  aggregratePointDetail: (state) => state.aggregratePointDetail,
  pointQRList: (state) => state.pointQRList.list,
  pointQRCount: (state) => state.pointQRList.count,
  pointQrDetail: (state) => state.pointQrDetail,
  releaseOtpList: (state) => state.releaseOtpList.list,
  releaseOtpCount: (state) => state.releaseOtpList.count,
  allQrTicketList: (state) => state.allQrTicketList.list,
  allQrTicketCount: (state) => state.allQrTicketList.count,
  otpList: (state) => state.otpList.list,
  otpCount: (state) => state.otpList.count,
  otpInactiveCount: (state) => state.otpList.inactiveCount,
};
