import store from '@/store';
import { template, templateSettings } from 'lodash';
import { FIELD_MAP, COMMON_MESSAGE } from '@/config/message';

templateSettings.interpolate = /{{([\s\S]+?)}}/g;

const message = {
  updated: (field) => {
    store.dispatch('ADD_ALERTS', {
      message: `${FIELD_MAP[field]}${COMMON_MESSAGE.updatedSuccess}`,
      type: 'success',
    });
  },
  created: (field) => {
    store.dispatch('ADD_ALERTS', {
      message: `${FIELD_MAP[field]}${COMMON_MESSAGE.createdSuccess}`,
      type: 'success',
    });
  },
  deleted: (field) => {
    store.dispatch('ADD_ALERTS', {
      message: `${FIELD_MAP[field]}${COMMON_MESSAGE.deletedSuccess}`,
      type: 'success',
    });
  },
  overMaxsize: (maxSize, isAudio = true) => {
    store.dispatch('ADD_ALERTS', {
      message: `${maxSize}${isAudio ? COMMON_MESSAGE.overMaxsize: COMMON_MESSAGE.overMaxsizeImg}`,
      type: 'error',
    });
  },
  noSelectedItem: () => {
    message.reset();
    store.dispatch('ADD_ALERTS', {
      message: `${COMMON_MESSAGE.noSelectedItem}`,
      type: 'error',
    });
  },
  noSelectedCategory: () => {
    message.reset();
    store.dispatch('ADD_ALERTS', {
      message: `${COMMON_MESSAGE.noSelectedCategory}`,
      type: 'error',
    });
  },
  statusEventRequired: () => {
    const message = `${COMMON_MESSAGE.statusEventRequired}`;
    const messageInState = store.getters.getAlerts.find(item => item.message === message);
    if (!messageInState) {
      store.dispatch('ADD_ALERTS', {
        message: message,
        type: 'error',
      });
    }
  },
  formDateRequired: () => {
    message.checkValidateMessage('formDateRequired');
  },
  formPointRequired: () => {
    message.checkValidateMessage('formPointRequired');
  },
  invalidTextboxPoint: () => {
    message.checkValidateMessage('invalidTextboxPoint');
  },
  invalidCalendar: () => {
    message.checkValidateMessage('invalidCalendar');
  },
  invalidPercent: () => {
    message.checkValidateMessage('invalidPercent');
  },
  invalidPointStore: () => {
    message.checkValidateMessage('invalidPointStore');
  },
  invalidChargePoint: () => {
    message.checkValidateMessage('invalidChargePoint');
  },
  invalidPaymentPoint: () => {
    message.checkValidateMessage('invalidPaymentPoint');
  },
  checkValidateMessage: (messageData) => {
    const message = `${COMMON_MESSAGE[messageData]}`;
    const messageInState = store.getters.getAlerts.find(item => item.message === message);
    if (!messageInState) {
      store.dispatch('ADD_ALERTS', {
        message: message,
        type: 'error',
      });
    }
  },
  reset: () => {
    store.dispatch('DELETE_ALERT');
  },
  showError: (messageKey, rule, value) => {
    const compiled = template(COMMON_MESSAGE[messageKey]);
    let error = '';
    message.reset();
    error = compiled({ [rule]: value });
    store.dispatch('ADD_ALERTS', {
      message: error,
      type: 'error',
    });
  },
  showSuccess: (messageKey, autoclose = false, rule, value = null) => {
    const compiled = template(COMMON_MESSAGE[messageKey]);
    let messageData = '';
    message.reset();
    messageData = compiled({ [rule]: value });
    store.dispatch('ADD_ALERTS', {
      message: value ? messageData : COMMON_MESSAGE[messageKey],
      type: 'success',
      autoclose: autoclose,
    });
  },
  showMessageSuccess: (messageText) => {
    message.reset();
    store.dispatch('ADD_ALERTS', {
      message: messageText,
      type: 'success',
    });
  },
  showMessageError: (messageText) => {
    message.reset();
    store.dispatch('ADD_ALERTS', {
      message: messageText,
      type: 'error',
    });
  },
}

export default message;
